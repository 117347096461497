body {
  padding-top: 5rem;
  font-family: 'Quicksand', sans-serif;
  font-size: 10.5pt;
  font-weight: 400;
  line-height: 22pt;
}

#root {
  max-width: 68em;
  margin: 0 auto;
}

@media (max-width: 520px) {
  .footer{
	font-size: 8.5pt !important;
  }
}

@media (min-width: 991px) {
  .nav-link{
	padding-left: 36px !important;
  }
}

@media (min-width: 1440px) {
}

p.main-short-desc {
  color: #00aeae;
}

p.center {
  text-align: center;
}

p.donation {
  margin-top: 8pt;
}

p.lineup {
  margin-bottom: 5pt;
}

p,ul {
  /* margin-bottom: 30px; */
}

h1{
  font-size: 24pt;
  font-weight: 300;
  color:#000;
  margin-bottom: 22px;
  margin-top: 35px;
}

h2{
  color:#b4b4b4;
  font-size: 14pt;
  font-weight: 700;
  margin-bottom: 20px;
}

h3 {
  color:#00aeae;
  font-size: 10.5pt;
  font-weight: 700;
  line-height: 22pt;
}

h1.main {
  text-align: center;
  font-size: 24pt;
  font-weight: 300;
  color:#000;
  margin-bottom: 40px;
}

h2.main {
  color:#b4b4b4;
  font-size: 14pt;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

h3.main {
  color:#00aeae;
  font-size: 10.5pt;
  font-weight: 700;
  line-height: 22pt;
  text-align: center;
  margin-bottom: 50px;
}

h1.legal {
  font-size: 24pt;
  font-weight: 300;
  padding-bottom: 32px;
}

h2.legal {
  font-size: 10.5pt;
  font-weight: 700;
  margin: 0;
}

h3.legal, h4.legal {
  font-size: 10.5pt;
  font-weight: 400;
  margin: 0;
}

.card-body {
  background: #f0f0f0;
  padding: 10pt;
}

.card-title {
  text-align: center;
  font-size: 20pt;
  font-weight: 300;
  color:#000;
  margin-bottom: 20px;
  text-align: left;
}

.card-subtitle {
  color:#00aeae !important;
  font-size: 10.5pt;
  font-weight: 700;
  line-height: 22pt;
  text-align: left;
  margin-bottom: 50px;
  line-height: 16.7pt;
  margin-top: 16px;
}

.card-text {
  color:#212529;
  text-align: left;
  font-size: 10.5pt;
  font-weight: 400;
  line-height: 15.7pt;
}

.jumbotron {
  padding-left: 36px;
  padding-right: 36px;
}

.jumbo_gray {
  background: #f0f0f0;
}

.jumbo_white {
  background: #fff;
}

.navbar {
  max-width: 68em;
  margin: 0 auto;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 7px;
  padding-bottom: 3px;
}

.infoacts {
  padding-left: 36px;
  padding-right: 36px;
}

.bg-navbar_red{ background:#e52520;}
.bg-navbar_cyan{ background:#00aeae;}
.nav-link , .navbar-brand{ color: #fff !important; cursor: pointer;}

.nav-link{
  font-size: 14pt;
  font-weight: 400;
  padding-right: 0px !important;
}

.nav-link:hover{ color: #e52520; }

.navbar-toggler{
  padding-right: 0px !important;
}

.navbar-brand {
  padding: 0;
}

.nav-item{
  flex: 1;
}

.footer{
  line-height: 12pt;
  max-width: 100%;
  font-size: 10.5pt;
  color: #fff;
}

.figure-img{
  padding-left: 36px;
  padding-right: 36px;
}

.container-fluid{
  padding-top: 36px;
  padding-bottom: 72px;
  padding-left: 36px;
  padding-right: 36px;
}
